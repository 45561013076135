<!--

    Copyright © 2016-2023 The Thingsboard Authors

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.

-->
<div class="tb-reset-password-content mat-app-background tb-dark" fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
  <mat-card appearance="raised" fxFlex="initial" class="tb-reset-password-card">
    <mat-card-header>
      <mat-card-title class="layout-padding tb-card-title">
        <span translate class="mat-headline-5 tb-card-title">login.password-reset</span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="isExpiredPassword" class="layout-padding tb-card-title">
        <div class="tb-card-title" translate>login.expired-password-reset-message</div>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
    </mat-progress-bar>
    <span style="height: 4px;" *ngIf="!(isLoading$ | async)"></span>
    <mat-card-content>
      <form [formGroup]="resetPassword" (ngSubmit)="onResetPassword()">
        <fieldset [disabled]="isLoading$ | async">
          <div tb-toast fxLayout="column" class="layout-padding">
            <span style="height: 50px;"></span>
            <mat-form-field class="mat-block tb-appearance-transparent">
              <mat-label translate>login.new-password</mat-label>
              <input matInput type="password" autofocus formControlName="newPassword"/>
              <mat-icon class="material-icons" matPrefix>lock</mat-icon>
              <tb-toggle-password matSuffix></tb-toggle-password>
            </mat-form-field>
            <mat-form-field class="mat-block tb-appearance-transparent">
              <mat-label translate>login.new-password-again</mat-label>
              <input matInput type="password" formControlName="newPassword2"/>
              <mat-icon class="material-icons" matPrefix>lock</mat-icon>
              <tb-toggle-password matSuffix></tb-toggle-password>
            </mat-form-field>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" fxLayoutAlign="start center"
                 fxLayoutAlign.gt-sm="center start">
              <button mat-raised-button color="accent" type="submit" [disabled]="(isLoading$ | async)">
                {{ 'login.reset-password' | translate }}
              </button>
              <button mat-button type="button" [disabled]="(isLoading$ | async)"
                      routerLink="/login">
                {{ 'action.cancel' | translate }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </mat-card-content>
  </mat-card>
</div>
