///
/// Copyright © 2016-2023 The Thingsboard Authors
///
/// Licensed under the Apache License, Version 2.0 (the "License");
/// you may not use this file except in compliance with the License.
/// You may obtain a copy of the License at
///
///     http://www.apache.org/licenses/LICENSE-2.0
///
/// Unless required by applicable law or agreed to in writing, software
/// distributed under the License is distributed on an "AS IS" BASIS,
/// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
/// See the License for the specific language governing permissions and
/// limitations under the License.
///

import { Inject, Pipe, PipeTransform } from '@angular/core';
import { WINDOW } from '@core/services/window.service';

// @dynamic
@Pipe({
  name: 'keyboardShortcut'
})
export class KeyboardShortcutPipe implements PipeTransform {

  constructor(@Inject(WINDOW) private window: Window) {}

  transform(value: string): string {
    if (!value) {
      return;
    }
    const keys = value.split('-');
    const isOSX = /Mac OS X/.test(this.window.navigator.userAgent);

    const seperator = (!isOSX || keys.length > 2) ? '+' : '';

    const abbreviations = {
      M: isOSX ? '⌘' : 'Ctrl',
      A: isOSX ? 'Option' : 'Alt',
      S: 'Shift'
    };

    return keys.map((key, index) => {
      const last = index === keys.length - 1;
      return last ? key : abbreviations[key];
    }).join(seperator);
  }

}
