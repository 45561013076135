<!--

    Copyright © 2016-2023 The Thingsboard Authors

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.

-->
<mat-form-field [formGroup]="conversationSlackFormGroup" class="mat-block">
  <mat-label>{{ labelText }}</mat-label>
  <input matInput type="text"
         #slackInput
         formControlName="conversation"
         (focusin)="onFocus()"
         [required]="required"
         [matAutocomplete]="slackAutocomplete">
  <button *ngIf="conversationSlackFormGroup.get('conversation').value && !disabled"
          type="button" matSuffix mat-icon-button aria-label="Clear"
          (click)="clear()">
    <mat-icon class="material-icons">close</mat-icon>
  </button>
  <mat-autocomplete class="tb-autocomplete"
                    #slackAutocomplete="matAutocomplete"
                    [displayWith]="displaySlackConversationFn"
                    >
    <mat-option *ngFor="let template of slackConversation$ | async" [value]="template" class="template-option">
      <span [innerHTML]="template.title | highlight:slackSearchText"></span>
    </mat-option>
    <mat-option *ngIf="!(slackConversation$ | async)?.length" [value]="null" class="tb-not-found">
      <div class="tb-not-found-content" (click)="$event.stopPropagation()">
        <div *ngIf="!textIsNotEmpty(slackSearchText); else searchNotEmpty">
          <span translate>notification.not-found-slack-recipient</span>
        </div>
        <ng-template #searchNotEmpty>
          <span>Not slack recipient matches {{ truncate.transform(slackSearchText, true, 6, &apos;...&apos;) }}</span>
        </ng-template>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="conversationSlackFormGroup.get('conversation').hasError('required')">
    {{ requiredText }}
  </mat-error>
</mat-form-field>
